import React from 'react';
import { useTranslation, Trans } from 'react-i18next';

import TextLoop from 'react-text-loop';
import BlockRevealAnimation from 'react-block-reveal-animation';

import Layout from '../components/layout';
import SEO from '../components/seo';
import AppDownloadButton from '../components/app-download-button';
import LocalizedLink from '../components/localized-link';
import languages from '../config/languages';

import './duty-free-district.scss';

import signUpIcon from '../assets/images/styled-icons/sign-up.svg';
import storesIcon from '../assets/images/styled-icons/stores.svg';
import shopIcon from '../assets/images/styled-icons/shop.svg';

const DutyFreeDistrictPage = () => {
  const { t, i18n } = useTranslation('DutyFreeDistrictPage');
  const isChinese = false;

  const otherCities = [
    'Firenze',
    'Verona',
    'Brescia',
    'Capri',
    'Varese',
    'Trieste',
    'Valtellina',
    'Milano',
    'Roma',
    'Aosta',
    'Treviso',
    'Bari',
    'Bologna',
    'Venezia',
    'Genova',
    'Napoli',
    'Padova',
    'Perugia',
    'Trento',
    'Bergamo',
  ];

  return (
    <Layout className="st-p-duty-free-district" hasDarkNavigation={true}>
      <SEO title={t('title')} />
      <div className="st-p-landing__simple-hero st-p-landing__simple-hero--dark has-text-centered">
        <div className="container">
          <h1 className={`st-p-duty-free-district__logo ${isChinese && 'is-chinese'}`}>
            <div className="st-p-duty-free-district__text">
              <BlockRevealAnimation delay={0.1} duration={0.6} color="#0a1b2f">
                <Trans i18nKey="DutyFreeDistrictPage:logo.dutyFree">
                  <span className="is-sr-only"></span>
                </Trans>{' '}
              </BlockRevealAnimation>
            </div>
            <div className="st-p-duty-free-district__text">
              <BlockRevealAnimation delay={0.3} duration={0.6} color="#0a1b2f">
                <Trans i18nKey="DutyFreeDistrictPage:logo.district"></Trans>
              </BlockRevealAnimation>
            </div>
          </h1>
        </div>
      </div>

      <div className="st-p-landing__section st-p-duty-free-district__contours-bg has-text-centered">
        <div className="container">
          <div className="columns is-centered">
            <div className="column is-four-fifths">
              <p className="subtitle is-3">
                <Trans i18nKey="DutyFreeDistrictPage:introDescription">
                  <span className="u-underline-styled"></span>
                </Trans>
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="st-p-landing__section is-small is-blue">
        <div className="container">
          <div className="columns">
            <div className="column is-one-third">
              <div className="title is-5 st-p-landing__title--line is-white">
                {t('whatIsADistrict.title')}
              </div>
            </div>
            <div className="column is-two-thirds">{t('whatIsADistrict.paragraph')}</div>
          </div>
        </div>
      </div>

      <div className="st-p-landing__section">
        <div className="container">
          <h2 className="st-p-landing__subtitle">{t('travelers.forTravelers')}</h2>
          <p className="title is-2">{t('travelers.title')}</p>
          <div className="columns">
            <div className="column">
              <div className="st-p-duty-free-district__icon-container">
                <img src={signUpIcon} alt="Sign up icon" />
              </div>
              <p className="title is-5 is-spaced">{t('travelers.signUp.title')}</p>
              <p className="subtitle is-6">{t('travelers.signUp.paragraph')}</p>
            </div>
            <div className="column">
              <div className="st-p-duty-free-district__icon-container">
                <img src={storesIcon} alt="Locate stores icon" />
              </div>
              <p className="title is-5 is-spaced">{t('travelers.stores.title')}</p>
              <p className="subtitle is-6">{t('travelers.stores.paragraph')}</p>
            </div>
            <div className="column">
              <div className="st-p-duty-free-district__icon-container">
                <img src={shopIcon} alt="Shop icon" />
              </div>
              <p className="title is-5 is-spaced">{t('travelers.shop.title')}</p>
              <p className="subtitle is-6">{t('travelers.shop.paragraph')}</p>
            </div>
          </div>

          <div className="section pb-0 field is-grouped is-grouped-centered">
            <div className="control">
              <AppDownloadButton type="ios"></AppDownloadButton>
            </div>
            <div className="control">
              <AppDownloadButton type="android"></AppDownloadButton>
            </div>
          </div>
        </div>
      </div>

      <div className="st-p-landing__section is-small is-primary-light has-text-centered">
        <div className="container">
          <h3 className="title is-5">{t('business.title')}</h3>
          <p>{t('business.paragraph')}</p>

          <div className="section pt-5 pb-0 field is-grouped is-grouped-centered">
            <div className="control">
              <a href="https://signup.stampit.co/" className="button is-dark">
                {t('business.action.becomeADutyFreeShop')}
              </a>
            </div>
            <div className="control">
              <LocalizedLink to="/business" className="button">
                {t('business.action.learnMore')}
              </LocalizedLink>
            </div>
          </div>
        </div>
      </div>

      <div className="st-p-landing__section st-p-duty-free-district__contours-bg st-p-duty-free-district__contours-bg--faded">
        <div className="container">
          <h3 className="title is-1 is-spaced">{t('activeDistricts.title')}</h3>
          <p className="subtitle is-5">{t('activeDistricts.paragraph')}</p>

          <div className="section pb-0">
            <div className="st-p-duty-free-district__location">
              <div className="columns">
                <div className="column is-one-quarter-tablet">
                  <h4 className="st-p-duty-free-district__text st-p-duty-free-district__location-name">
                    Como
                  </h4>
                </div>
                <div className="column">
                  <p>{t('districtDescription.como')}</p>
                </div>
              </div>
            </div>

            <div className="st-p-duty-free-district__location">
              <div className="columns">
                <div className="column is-one-quarter-tablet">
                  <h4 className="st-p-duty-free-district__text st-p-duty-free-district__location-name">
                    Vicenza
                  </h4>
                </div>
                <div className="column">
                  <p>{t('districtDescription.vicenza')}</p>
                </div>
              </div>
            </div>

            <div className="st-p-duty-free-district__location mt-6">
              <div className="columns is-vcentered">
                <div className="column">
                  <div className="title is-5">{t('activeDistricts.join.title')}</div>
                  <p>{t('activeDistricts.join.paragraph')}</p>
                </div>
                <div className="column is-narrow">
                  <div className="control">
                    <a href="https://signup.stampit.co/" className="button">
                      {t('business.action.becomeADutyFreeShop')}
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="st-p-landing__section section is-medium is-small is-blue">
        <div className="container">
          <div className="columns is-vcentered">
            <div className="column">
              <TextLoop interval={1000} springConfig={{ stiffness: 400, damping: 30 }}>
                {otherCities.map((city, index) => (
                  <div className="st-p-duty-free-district__text" key={index}>
                    <div className="title is-1">{city}</div>
                  </div>
                ))}
              </TextLoop>
            </div>
            <div className="column is-two-thirds">
              <div className="title is-4">{t('underDevelopment.title')}</div>
              <p>{t('underDevelopment.description')}</p>
            </div>
          </div>
        </div>
      </div>

      <div className="st-p-landing__section is-small is-grey-light">
        <div className="container">
          <div className="columns">
            <div className="column">
              <p className="st-p-landing__subtitle">{t('finalCta.travelers.forTravelers')}</p>
              <h4 className="title is-4">{t('finalCta.travelers.title')}</h4>

              <p>{t('finalCta.travelers.paragraph')}</p>

              <div className="section pb-0 field is-grouped">
                <div className="control">
                  <AppDownloadButton type="ios"></AppDownloadButton>
                </div>
                <div className="control">
                  <AppDownloadButton type="android"></AppDownloadButton>
                </div>
              </div>
            </div>
            <div className="column">
              <p className="st-p-landing__subtitle">{t('finalCta.business.forBusiness')}</p>
              <h4 className="title is-4">{t('finalCta.business.title')}</h4>

              <p>{t('finalCta.business.paragraph')}</p>

              <div className="section pb-0 field is-grouped">
                <div className="control">
                  <a href="https://signup.stampit.co/" className="button is-medium">
                    {t('business.action.becomeADutyFreeShop')}
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default DutyFreeDistrictPage;
